/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const capitalHero = {
  title: "Get the loan \n" + "you need—fast",
  subtext:
    "Solve short-term cash flow gaps or seize the moment to grow your business with fast, affordable funding from SpotOn Capital.",
  heroImg: "capital-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/capital/demo",
  },
  fatTitle: "Capital",
};

export const capitalTrusted = [
  {
    title: "$25.6k",
    subtext: "average loan",
  },
  {
    title: "24hrs",
    subtext: "on average to approve your application and deposit funds",
  },
  {
    title: "83%",
    subtext: "renewal rate",
  },
];

export const capitalWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "A stress-free experience",
  contentList: [
    "Save time and get funds quickly with our simple application and approval process",
    "Know what you are paying from the start: one fixed fee with no periodic interest charges or hidden costs",
    "Repay as you earn: a fixed percentage of your daily sales is deducted automatically and applied to your balance",
    "Renew as often as you need",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/capital/demo",
  },
};

export const capitalLargeFeatures = {
  sectionTitle: "The loan you need. Your way",
  featureBlocks: [
    {
      blockTitle: "How it works",
      blockSubTitle:
        "SpotOn Capital is part of our end-to-end platform built to help your business compete and win in a fast changing world. When you use SpotOn for payments, our team will automatically notify you when you qualify for a small business loan.",
      blockImg: "capital-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/capital/demo",
      },
    },
    // {
    //   blockTitle: "Get funds in 4 easy steps",
    //   blockImg: "capital-b.png",
    //   blockList: [
    //     "Log in to your SpotOn Dashboard to review your loan offer",
    //     "Choose your loan amount",
    //     "Complete the quick application and agree to the terms",
    //     "Get funds transferred directly into your account as soon as the next business day upon approval",
    //   ],
    //   forceReverse: false,
    //   ctaPrime: {
    //     ctaTitle: "Learn more",
    //     ctaTarget: "/products/capital/demo",
    //   },
    // },
    {
      blockTitle: "Easy repayment. No interest charges or hidden fees",
      blockSubTitle:
        "SpotOn Capital makes paying off your loan stress-free by applying a fixed percentage of your daily sales directly toward your balance. In short, you pay more when sales are good, and pay less when sales are slow. Log in to your dashboard anytime to view your progress or make additional payments.",
      blockImg: "capital-c.png",
      blockList: [
        "One fixed fee based on loan amount",
        "No periodic interest charges",
        "No monthly bills or late fees",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/capital/demo",
      },
    },
  ],
};

export const capitalBusinessTypesCar = {
  title: "Built for businesses like yours",
  subtext: "We know businesses like yours, and we’re here to help you win.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const capitalVideo = {
  title: "See how it works",
  videoBtn: "Watch video",
  posterImg: "capital-poster.png",
  videoUrlId: "zWmkG5lk7Hw",
};

export const capitalSolutionsSection = {
  title: "More great tools to run your business",
  subText: `Save time and grow revenue with integrated SpotOn technology that makes sense for your business.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/products/capital/demo",
  },
};

export const capitalTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const capitalIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept capitals and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const capitalCTA = {
  title: "Book your consultation",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/capital/demo",
  },
};

export const capitalFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const capitalSolutionsSlides = [
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Restaurants",
    link: "/restaurants",
    img: "Restaurant.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
];
