import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  capitalHero,
  capitalTrusted,
  capitalWhiteGlove,
  capitalLargeFeatures,
  capitalBusinessTypesCar,
  capitalVideo,
  capitalSolutionsSection,
  capitalSolutionsSlides,
  capitalTestimonials,
  capitalCTA,
  // capitalFaqs,
} from "../../../data/product-pages/capital-data";
import ogImage from "../../../images/global_assets/og-image.png";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";
import quote from "../../../images/svg/quote.svg";
import fade from "../../../images/svg/light-fade-bg.svg";

const Hero = loadable(() => import("../../../components/Hero/hero"));
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);

const whiteGloveImport = "marketing-pp-wg.png";

const capital = () => {
  return (
    <Layout>
      <SEO
        title="Working Capital Loans for Small Businesses | SpotOn"
        description="Run your business and your finances all-in-one place. SpotOn Capital helps you get the small business loan you need. Contact us today."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/capital/"
      />
      <Hero sectionData={capitalHero} circleBg={false} />
      <TrustedNumbers numbersArray={capitalTrusted} bottomOn />
      <WhiteGlove
        sectionData={capitalWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={capitalLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      {/* <section className="py-[80px] mt-[32px] max-w-[883px] mx-auto z-0 relative overflow-hidden">
        <img src={quote} className="table mb-10 mx-auto" alt="" />
        <img
          src={fade}
          className="absolute t-0 z-0 w-[500px]"
          style={{
            left: "50%",
            transform: "translateX(-50%)",
          }}
          alt=""
        />
        <p className="text-center text-black-200 leading-[160%] mx-8 xl:mx-0">
          “I think the biggest thing with the SpotOn Capital for us is that I’m
          not giving investment presentations. We can keep that in house and go
          at our own pace. We can fund the opening of our new business with
          money from our current businesses. It’s super streamlined. We’re not
          bringing in additional money, or outside funding, we can do it
          in-house and that works well for us, as a bootstrap operation where
          we’re pulling out of our own pocket for these businesses and we don’t
          have big backing from a corporate chain.”
        </p>
        <Heading
          level={2}
          injectionType={4}
          className="text-center mb-0 mt-10"
          styles={{ marginBottom: 0 }}
        >
          Jayson Whiteside
        </Heading>
        <p className="text-center text-black-400 xl:mt-[-16px]">
          Co-Owner, Bardo and Vana
        </p>
      </section> */}
      <BusinessTypes sectionData={capitalBusinessTypesCar} />
      {/*
      <SuccessStories sectionData={heroSuccess} slides={heroSlides} />
*/}
      <VideoSection sectionData={capitalVideo} />
      <section className="pt-4 pb-10 lg:pt-[90px] lg:pb-[90px]">
        <Heading
          level={2}
          injectionType={3}
          className="text-center"
          withBlueSymbol
          symbol="."
        >
          Terms and Conditions
        </Heading>
        <div className="table mx-auto my-0">
          <CtaPrimary
            title="Read T&C's"
            newTab
            target="https://help.spoton.com/space/SK/2078867587/Terms+%26+Conditions+%7C+SpotOn+Capital"
            large
          />
        </div>
      </section>
      <PartnerLogos />
      <ProductLines
        sectionData={capitalSolutionsSection}
        productSlides={capitalSolutionsSlides}
      />
      <TestmonialReviews sectionData={capitalTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={capitalCTA} />

      {/* <Faqs sectionData={capitalFaqs} /> */}
    </Layout>
  );
};

export default capital;
